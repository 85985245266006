import {UserProfileFragment} from "../generated/graphql";

export const CREATE_HARVEST_GROUP_PERMISSION = "create_harvest_group";
export const CREATE_ZONE_PERMISSION = "create_zone";
export const UPDATE_HARVEST_GROUP_PERMISSION = "update_harvest_group";
export const ARCHIVE_HARVEST_GROUP_PERMISSION = "archive_harvest_group";
export const CREATE_STRAIN_PERMISSION = "create_strain";
export const CREATE_LOG_ENTRY_PERMISSION = "create_log_entry";
export const APPROVE_LOG_ENTRY_PERMISSION = "approve_log_entry";
export const UPDATE_ZONE_PERMISSION = "update_zone";
export const UPDATE_LOG_ENTRY_PERMISSION = "update_log_entry";
export const UPDATE_OWNED_LOG_ENTRY_PERMISSION = "update_owned_log_entry";
export const LIST_RECIPES_PERMISSION = "list_recipes";
export const CREATE_RECIPE_PERMISSION = "create_recipe";
export const UPDATE_RECIPE_PERMISSION = "update_recipe";
export const LIST_NUTRIENTS_PERMISSION = "list_nutrients";
export const CREATE_NUTRIENT_PERMISSION = "create_nutrient";
export const UPDATE_NUTRIENT_PERMISSION = "update_nutrient";
export const TOGGLE_NUTRIENT_ACTIVE_PERMISSION = "toggle_nutrient_active";
export const CREATE_USER_PERMISSION = "create_user";
export const COMPLETE_HARVEST_PERMISSION = "complete_harvest";
export const APPROVE_HARVEST_COMPLETION_PERMISSION =
  "approve_harvest_completion";
export const CREATE_TASK_PERMISSION = "create_task";
export const DELETE_LOG_ENTRY_PERMISSION = "delete_log_entry";
export const CREATE_HARVEST_REPORT_PERMISSION = "create_harvest_report";
export const CREATE_HARVEST_GROUP_TEMPLATE_PERMISSION =
  "create_harvest_group_template";
export const ARCHIVE_HARVEST_GROUP_TEMPLATE_PERMISSION =
  "archive_harvest_group_template";
export const UPDATE_HARVEST_GROUP_TEMPLATE_PERMISSION =
  "update_harvest_group_template";
export const UPDATE_ACTIVE_HARVEST_GROUP_TEMPLATE_PERMISSION =
  "update_active_harvest_group_template_permission";
export const UPDATE_HARVEST_REPORT_PERMISSION = "update_harvest_report";
export const VIEW_CULTIVATION_ANALYTICS_PERMISSION = "view_analytics";
export const VIEW_TABLEAU_PERMISSION = "view_tableau";
export const VIEW_LAB_TESTING_PERMISSION = "view_lab_testing";
export const LIST_USERS_PERMISSION = "list_users";
export const UPDATE_USER_PERMISSION = "update_user";
export const TOGGLE_USER_ACTIVATION_PERMISSION = "toggle_user_activation";

export const UPDATE_ROLE_PERMISSION = "update_role";

export const LIST_MAINTENANCE_REQUESTS_PERMISSION = "list_maintenance_requests";
export const CREATE_MAINTENANCE_REQUEST_PERMISSION =
  "create_maintenance_request";
export const RESOLVE_MAINTENANCE_REQUEST_PERMISSION =
  "resolve_maintenance_request";

export const CREATE_REFERENCE_BUD_FORM_FACTOR_PERMISSION =
  "create_reference_bud_form_factor";
export const UPDATE_REFERENCE_BUD_FORM_FACTOR_PERMISSION =
  "update_reference_bud_form_factor";
export const LIST_REFERENCE_BUD_FORM_FACTORS_PERMISSION =
  "list_reference_bud_form_factors";

export const CREATE_CALENDAR_BRANCH_PERMISSION = "create_calendar_branch";
export const DUPLICATE_CALENDAR_BRANCH_PERMISSION = "duplicate_calendar_branch";
export const DELETE_CALENDAR_BRANCH_PERMISSION = "delete_calendar_branch";
export const UPDATE_CALENDAR_BRANCH_PERMISSION = "update_calendar_branch";
export const REVIEW_CALENDAR_BRANCH_PERMISSION = "review_calendar_branch";
export const APPROVE_CALENDAR_BRANCH_PERMISSION = "approve_calendar_branch";
export const LIST_CALENDAR_BRANCHES_PERMISSION = "list_calendar_branches";
export const VIEW_CALENDAR_BRANCH_PERMISSION = "view_calendar_branch";
export const VIEW_MAIN_CALENDAR_PERMISSION = "view_main_calendar";

export const ENVIRONMENTAL_UPLOAD_PERMISSION = "environmental_upload"
export const PAPERLYTICS_PERMISSION = "paperlytics"

// PROCESING MODULE PERMISSIONS
export const CREATE_PROCESSING_GROUP_TEMPLATE_PERMISSION =
  "create_processing_group_template";
export const VIEW_PROCESSING_ANALYTICS_PERMISSION = "view_processing_analytics";
export const CREATE_PROCESSING_GROUP_PERMISSION = "create_processing_group";
export const ARCHIVE_PROCESSING_GROUP_PERMISSION = "archive_processing_group";
export const CREATE_PROCESSING_JOB_PERMISSION = "create_processing_job";
export const UPDATE_PROCESSING_JOB_PERMISSION = "update_processing_job";
export const CREATE_PROCESSING_JOB_LOG_PERMISSION = "create_processing_job_log";
export const UPDATE_PROCESSING_JOB_LOG_PERMISSION = "update_processing_job_log";
export const FINISH_PROCESSING_STRAIN = "finish_processing_strain";
export const VIEW_PROCESSING_CALENDAR_PERMISSION = "view_processing_calendar";

// FINANCE MODULE PERMISSIONS
export const VIEW_FINANCE_EXPENSES_PERMISSION = "view_finance_expenses";
export const MODIFY_FINANCE_EXPENSES_PERMISSION = "modify_finance_expenses";

// HIDDEN PERMISSIONS - not in ALL_PERMISSIONS, not in PERMISSIONS_LABELS, for hidden/palomar-only feature toggles
export const PALOMAR_NUTRIENT_RECONCILE_PERMISSION = "_palomar_nutrient_reconcile";
export const CREATE_NEW_ORG = "_create_new_org"

export const PERMISSIONS_LABELS = {
  harvestGroups: {
    [CREATE_HARVEST_GROUP_PERMISSION]: "Create Harvest Group",
    [UPDATE_HARVEST_GROUP_PERMISSION]: "Update Harvest Group",
    [ARCHIVE_HARVEST_GROUP_PERMISSION]: "Archive Harvest Group",
    [COMPLETE_HARVEST_PERMISSION]: "Complete Harvest",
    [APPROVE_HARVEST_COMPLETION_PERMISSION]: "Approve Harvest Completion",
    [CREATE_TASK_PERMISSION]: "Create Task",
    [CREATE_HARVEST_REPORT_PERMISSION]: "Create Harvest Report",
    [UPDATE_HARVEST_REPORT_PERMISSION]: "Update Harvest Report",
    [VIEW_CULTIVATION_ANALYTICS_PERMISSION]: "View Cultivation Analytics",
    [CREATE_HARVEST_GROUP_TEMPLATE_PERMISSION]: "Create Harvest Group Template",
    [UPDATE_HARVEST_GROUP_TEMPLATE_PERMISSION]: "Update Harvest Group Template",
    [ARCHIVE_HARVEST_GROUP_TEMPLATE_PERMISSION]:
      "Archive Harvest Group Template",
    [UPDATE_ACTIVE_HARVEST_GROUP_TEMPLATE_PERMISSION]:
      "Update Active Harvest Group Template",
  },
  zones: {
    [CREATE_ZONE_PERMISSION]: "Create Zone",
    [UPDATE_ZONE_PERMISSION]: "Update Zone",
  },
  strains: {
    [CREATE_STRAIN_PERMISSION]: "Create Strain",
  },
  logEntries: {
    [CREATE_LOG_ENTRY_PERMISSION]: "Create Log Entry",
    [UPDATE_LOG_ENTRY_PERMISSION]: "Update Log Entry",
    [APPROVE_LOG_ENTRY_PERMISSION]: "Approve Log Entry",
    [UPDATE_OWNED_LOG_ENTRY_PERMISSION]: "Update Owned Log Entry",
    [DELETE_LOG_ENTRY_PERMISSION]: "Delete Log Entry",
  },
  recipesAndNutrients: {
    [LIST_RECIPES_PERMISSION]: "List Recipes",
    [CREATE_RECIPE_PERMISSION]: "Create Recipe",
    [UPDATE_RECIPE_PERMISSION]: "Update Recipe",
    [LIST_NUTRIENTS_PERMISSION]: "List Nutrients",
    [CREATE_NUTRIENT_PERMISSION]: "Create Nutrient",
    [UPDATE_NUTRIENT_PERMISSION]: "Update Nutrient",
    [TOGGLE_NUTRIENT_ACTIVE_PERMISSION]: "Toggle Nutrient Activation",
  },
  users: {
    [LIST_USERS_PERMISSION]: "List Users",
    [CREATE_USER_PERMISSION]: "Create User",
    [UPDATE_USER_PERMISSION]: "Update User",
    [TOGGLE_USER_ACTIVATION_PERMISSION]: "Toggle User Activation",
    [UPDATE_ROLE_PERMISSION]: "Update Role Permissions",
  },
  maintenanceRequests: {
    [LIST_MAINTENANCE_REQUESTS_PERMISSION]: "List Maintenance Requests",
    [CREATE_MAINTENANCE_REQUEST_PERMISSION]: "Create Maintenance Request",
    [RESOLVE_MAINTENANCE_REQUEST_PERMISSION]: "Resolve Maintenance Request",
  },
  referenceBudFormFactor: {
    [CREATE_REFERENCE_BUD_FORM_FACTOR_PERMISSION]:
      "Create Reference Bud Form Factor",
    [UPDATE_REFERENCE_BUD_FORM_FACTOR_PERMISSION]:
      "Update Reference Bud Form Factor",
    [LIST_REFERENCE_BUD_FORM_FACTORS_PERMISSION]:
      "List Reference Bud Form Factors",
  },
  calendar: {
    [CREATE_CALENDAR_BRANCH_PERMISSION]: "Create Calendar Branch",
    [DUPLICATE_CALENDAR_BRANCH_PERMISSION]: "Duplicate Calendar Branch",
    [DELETE_CALENDAR_BRANCH_PERMISSION]: "Delete Calendar Branch",
    [UPDATE_CALENDAR_BRANCH_PERMISSION]: "Update Calendar Branch",
    [REVIEW_CALENDAR_BRANCH_PERMISSION]: "Review Calendar Branch",
    [APPROVE_CALENDAR_BRANCH_PERMISSION]: "Approve Calendar Branch",
    [LIST_CALENDAR_BRANCHES_PERMISSION]: "List Calendar Branches",
    [VIEW_CALENDAR_BRANCH_PERMISSION]: "View Calendar Branch",
    [VIEW_MAIN_CALENDAR_PERMISSION]: "View Main Calendar",
  },
  processingGroups: {
    [CREATE_PROCESSING_GROUP_PERMISSION]: "Create Processing Group",
    [ARCHIVE_PROCESSING_GROUP_PERMISSION]: "Archive Processing Group",
    [VIEW_PROCESSING_CALENDAR_PERMISSION]: "View Processing Calendar",
    [CREATE_PROCESSING_JOB_PERMISSION]: "Create Processing Job",
    [UPDATE_PROCESSING_JOB_PERMISSION]: "Update Processing Job",
    [CREATE_PROCESSING_JOB_LOG_PERMISSION]: "Create Processing Job Log",
    [UPDATE_PROCESSING_JOB_LOG_PERMISSION]: "Update Processing Job Log",
    [FINISH_PROCESSING_STRAIN]: "Finish Processing Strains",
    [VIEW_PROCESSING_ANALYTICS_PERMISSION]: "View Processing Analytics",
    [CREATE_PROCESSING_GROUP_TEMPLATE_PERMISSION]:
      "Create Processing Group Template",
  },
  finance: {
    [VIEW_FINANCE_EXPENSES_PERMISSION]: "View Finance Expenses",
    [MODIFY_FINANCE_EXPENSES_PERMISSION]: "Modify Finance Expenses",
  },
  environmental: {
    [ENVIRONMENTAL_UPLOAD_PERMISSION]: "Environmental Data Upload"
  },
  paperlytics: {
    [PAPERLYTICS_PERMISSION]: "Upload Paperlytics Images"
  }
} as { [key: string]: { [key: string]: string } };

export const ALL_PERMISSIONS = Object.keys(PERMISSIONS_LABELS).reduce(
  (list: string[], group) => [...list, ...Object.keys(PERMISSIONS_LABELS[group])],
  []
);

export const getUserPermissions = (user: UserProfileFragment) => user?.userPermissions || [];
export const getRolePermissions = (user: UserProfileFragment) => user?.role?.permissions || [];
export const hasPermission = (user: UserProfileFragment, p: string) =>
  getUserPermissions(user).includes(p) || getRolePermissions(user).includes(p);
