import { useRouter } from "next/router";
import { useAuth } from "../hooks/auth";

const Index = () => {
  const { currentUser, loading } = useAuth();
  const router = useRouter();

  if (loading) return null;
  if (process.browser) {
    if (!currentUser?.id) {
      router.push("/auth/login");
    } else {
      router.push("/app");
    }
  }
  return null;
};

export default Index;
